.swiper_logo {
  .swiper {
    height: 260px;
    padding: 0px 40px 40px 40px;
  }

  .swiper-button-prev:after,
  .swiper-button-next:after {
    font-size: 30px;
  }

  .swiper-slide .ant-image {
    height: 100%;
    width: 100%;
    img {
      height: 100%;
      object-fit: contain;
    }
  }
}
