

.container-recuitment{
    width   : 100%;
    height  : 100vh;
   
}
.background-out{
    background-color    : #2e3338;
    padding             : 30px 0px 30px 0px;

}
.container-recuitment input{
    height                      : 53px;
    background-color            : #dadada;
    border-bottom-left-radius   : 0;
    border-top-left-radius      : 0;
    border                      : none;
    border-radius               : 0.25rem !important;
    font-weight                 : 500 !important
}
.container-recuitment textarea{
    /* height                      : 53px; */
    background-color            : #dadada;
    border-bottom-left-radius   : 0;
    border-top-left-radius      : 0;
    border                      : none;
    border-radius               : 0.25rem !important;
    font-weight                 : 500 !important
}
.container-recuitment .ant-select-selector{
    height                      : 53px !important;
    background-color            : #dadada !important;
    border-bottom-left-radius   : 0 !important;
    border-top-left-radius      : 0 !important;
    border                      : none !important;
    border-radius               : 0.25rem !important;
    font-weight                 : 500 !important
}
.container-recuitment .ant-select-selector input{
    height: 53px !important;
   
}
.container-recuitment .ant-select-selection-placeholder{
    display     : flex;
    align-items : center;
    color       : #2e3338;
}
.container-recuitment .ant-select-selection-item{
    display     : flex;
    align-items : center;
    color       : #2e3338;
    font-weight : 500 !important
}
.container-recuitment .ant-select-selector input::placeholder{
    color: #2e3338;
}
.container-recuitment input::placeholder{
    color: #8a9196;
}
.container-recuitment .ant-form-item-required{
    color: white !important;
    font-size: 16px !important;
}
.btn-submit-cv{
    border          : none !important;
    border-radius   : 0.25rem !important;
    background-color: #f1a217 !important;
    margin-top      : 24px;
    height          : 53px !important;
    width           : 100%;
    color           : #eee !important;
    font-weight     : 700 !important;
    text-transform  : uppercase;
}
.captcha-box {
    display              : flex;
    justify-content      : center;
    align-items          : center;
    width                : 100%;
    height               : 45px;
    border-radius        : 0.25rem !important;
    background-color     : #911b1c;
    color                : #fff;
    font-size            : 20px;
    line-height          : 1;
    padding              : 8px;
    text-align           : center;
    -webkit-user-select  : none;
    -webkit-touch-callout: none;
    -moz-user-select     : none;
    -ms-user-select      : none;
    user-select          : none;
  }
  .ant-upload-drag-container{
    background-color: white !important;
  }
  .ant-upload{
    padding: 0 !important;
  }
  .ant-upload-drag-icon{
    margin-bottom: 0px !important;
  }
  .container-recuitment .ant-form-item{
    margin-bottom: 0 !important;
  }
  .container-recuitment .ant-upload-list-item-card-actions{
    display: block !important;
}