$background: #e5e5e5;
$background-default: #f6f6f6;

$primary-default: #4ad143;
$primary-dark: #3fa641;
$primary-light: #a8ff9f;
$primary-background: #f1f9f0;
$default-background: #10ba08;

$secondary-default: #1b211a;
$secondary-dark: #727970;
$secondary-light: #707d6c;
$secondary-background: #f4f4ec;

$pastel-green: #f0faf0;
$pastel-orange: #fcf7f3;
$pastel-red: #ffedeb;
$pastel-yellow: #fffde7;

$warning: #ff3f3c;
$color-red: #e3282c;

$color-border: #e3282c;
$color-title: #e3282c;

$color-placeholder: #727272;
$color-text: #000000;
$color-900: #1b211a;
$color-700: #5a6159;
$color-600: #6e756c;
$color-400: #b5bdb4;
$color-100: #e9e9e9;
$color-15: #eef0f1;
$color-100: #e9e9e9;
$white: #ffffff;
$red: #eb5757;
$blue: #2f80ed;
$black: #000000;
$orange: #f2994a;
$light-black: #11263c;
$grey-600: #555555;
$grey-8: #2e2e3a;
$grey-5: #70707c;
$grey-4: #a9b7db;
$grey-3: #a9a9b7;
$grey-0: #ffffff;
$grey-600: #555555;

$accents-red: #fd4438;
$accents-orange: #ff7a2f;
$accents-verdepom: #24ca49;
$accents-azure: #0062ff;
$accents-thistle: #c2b7cd;
$accents-amethyst: #a162f7;
$accents-aquamarine: #3dd598;
