@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900;1000&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // font-family: "Montserrat", sans-serif;
  // font-family: "Noto Sans", sans-serif;
  font-family: "Mulish", sans-serif;
  letter-spacing: 0.01em;
}

@import "variables";
@import "mixins";
@import "resetCSS";
@import "global";
