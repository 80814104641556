.product_container {
  .product_header {
    text-align: center;
    p {
      @include font-style(16px, 400, $color-text);
      margin: 30px 0px;
      text-align: center;
    }
  }
  .product_item_container {
    margin-bottom: 80px;
    h3 {
      @include font-style(32px, 500, $color-red);
      margin-bottom: 40px;
      // text-transform: uppercase;
    }
    .ant-card {
      border: none;
      box-shadow: 0px 0px 6px 0px #00000040;
      height: 334px;
      .ant-card-cover {
        margin: 0px;
        width: 100%;
        height: 60%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .ant-card-body {
        padding: 18px 10px 10px 10px;
        .ant-card-meta-title {
          @include font-style(18px, 500, #333);
        }
        .ant-card-meta-description {
          @include font-style(16px, 400, #333);
          text-align: left;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .ant-card:hover {
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      .ant-card-meta-description {
        color: $color-red;
      }
    }
  }
}
