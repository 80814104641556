.footer-bg {
  background-color: #f6f6f6;
  padding-top: 25px;
  padding-bottom: 25px;

  .footer-container {
    .footer-logo {
      margin-bottom: 30px;
    }
    .footer-menu {
      gap: 20px;
      display: flex;
      flex-direction: column;
    }

    .footer-info {
      gap: 10px;
      display: flex;
      flex-direction: column;
    }

    .footer-copyright {
      border-top: 1px solid #bbbbbb;
      padding-top: 31px;
      margin-top: 31px;
    }
  }
}
.mt-4 {
  margin-top: 4px;
}

@media only screen and (max-width: 576px) {
  .footer-logo {
    display: flex;
    justify-content: center;
  }

  // .layout-mobile {
  //   display: none;
  // }
}
