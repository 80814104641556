.flex-center {
  @include flex-center();
}

.flex-between {
  @include flex-between();
}
.flex-item-center {
  @include flex-item-center();
}
.flex-jus-end {
  @include flex-jus-end();
}

.flex-jus-center {
  @include flex-jus-center();
}

.flex-column-item-center {
  @include flex-column-item-center();
}
.flex-column-between {
  @include flex-column-between();
}
.flex-item-end {
  @include flex-item-end();
}
.bg-default {
  background-color: $background-default;
}
.container-wrapper {
  width: 100%;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

.container {
  width: 60%;
  margin: 0px auto;
  padding: 0;
}

.w-100 {
  width: 100%;
}

.bold-500 {
  font-weight: 500;
}

.mt-16 {
  display: flex;
  gap: 5px;
  margin-top: 16px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-30 {
  margin-bottom: 30px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-50 {
  margin-top: 50px;
}
.mb-50 {
  margin-bottom: 50px;
}

.text-center {
  text-align: center;
  line-height: 1.2;
}

.fz-40 {
  font-size: 40px;
}

.fz-20 {
  font-size: 20px;
}
.fz-16 {
  font-size: 16px;
}
.bold-500 {
  font-weight: 500;
}

.bold-600 {
  font-weight: 600;
}

.bold-700 {
  font-weight: 700;
}
.bold {
  font-weight: bold;
}
.color-red {
  color: $color-red;
}

.color-white {
  color: white;
}

.line-height-24 {
  line-height: 20px;
}

// .layout-pc {
//   display: block;
// }
.layout-mobile {
  display: none;
}
a:hover {
  color: $color-red;
}
.box-style {
  margin-top: 120px;
}

.pagination-wrapper {
  .ant-pagination .ant-pagination-item:not(.ant-pagination-item-active):hover {
    background-color: $color-red;
    transition: all 0.3s ease-in-out 0s;
    a {
      color: white;
      transition: all 0.3s ease-in-out 0s;
      font-size: 18px;
    }
  }
  .ant-pagination-item-active {
    // border: none;
    border-color: $color-red;
    background-color: $color-red;
    a {
      color: white;
      font-size: 18px;
    }
    &:hover {
      color: $color-red;
      a {
        color: white;
      }
    }
  }
}
.mobile {
  display: none !important;
}

.PC {
  display: block !important;
}
@media only screen and (max-width: 1680px) {
  .container {
    width: 75%;
  }
}

@media only screen and (max-width: 1440px) {
  .container {
    width: 80%;
  }
}

@media only screen and (max-width: 1280px) {
  .container {
    width: 90%;
  }
}

@media only screen and (max-width: 1024px) {
  .layout-pc {
    display: none;
  }
  .layout-mobile {
    display: flex;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    width: 92%;
  }
}

@media only screen and (max-width: 600px) {
  .mobile {
    display: block !important;
  }

  .PC {
    display: none !important;
  }
  .box-style {
    margin-top: 100px;
  }
}
