// banner slider
.slider-banner {
  height: 100%;
}

.slider-item {
  color: #242c42;
  height: 100%;
}

.bg-mobile {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.bg-1 {
  background-image: url(../../../assets/img/slider/Banner1.webp);
}

.bg-2 {
  background-image: url(../../../assets/img/slider/Banner2.webp);
}
.bg-3 {
  background-image: url(../../../assets/img/slider/slider-3.jpg);
}

.slider-item .slider-1,
.slider-item .slider-2,
.slider-item .slider-3,
.slider-item .slider-4 {
  background-image: url(../../../assets/img/slider/Banner1.webp);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  display: table;
}

.slider-item .slider-2 {
  background-image: url(../../../assets/img/slider/Banner2.webp);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}

.slider-item .slider-2 .slider-caption {
  text-align: center;
}

.slider-item .slider-3 {
  background-image: url(../../../assets/img/slider/slider-3.jpg);
}

.slider-item .slider-4 {
  background-image: url(../../../assets/img/slider/slider-4.jpg);
}

.baner-content {
  padding: 32px;
  width: 500px;
  background: rgba(255, 255, 255, 0.7);
}

//portfilio-containner
.info-solution {
  width: 70%;
}

.ant-card-meta-description {
  text-align: center;
}

.btn-tabs {
  .ant-tabs-nav {
    margin-bottom: 30px;
  }
  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: unset;
  }
  .ant-tabs-tab {
    border: 2px solid $color-red;
    border-radius: 8px;
    color: $color-red;
    padding: 10px 16px;
    &:hover {
      .ant-tabs-tab-btn {
        color: white !important;
      }
      background-color: $color-red;
    }
  }
  .ant-tabs-tab-active {
    background-color: $color-red;
    .ant-tabs-tab-btn {
      color: white !important;
    }
  }
  .ant-tabs .ant-tabs-ink-bar {
    background-color: unset;
  }
}
.card-product {
  height: 350px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: space-between;
  box-shadow: 0px 0px 12px 0px #ebe9e9;
  cursor: pointer;
  .product-img {
    height: 230px;
    width: 100%;
    .ant-image {
      width: 100%;
      height: 100%;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      overflow: hidden;
      border-radius: 8px 8px 0px 0px;
    }
  }
  .product-content {
    height: 100px;
    padding: 0px 14px;
    line-height: 20px;
    h4 {
      margin-bottom: 10px;
      color: green;
    }
  }
  &:hover {
    box-shadow: 0px 0px 10px 0px #a09f9f;
    transition: all 0.3s ease-in-out 0s;
  }
}
//solution

.solution-wrapper {
  padding-top: 60px;
  padding-bottom: 60px;
}
.card-solution {
  @include flex-column-item-center();
  background-color: white;
  padding: 30px 18px 52px 18px;
  border-radius: 10px;
  height: 340px;
  box-shadow: 0px 5px 10px 0px #dadada;
  .card-head {
    @include flex-column-item-center();
    gap: 12px;
    height: 150px;
  }
  &:hover {
    p {
      color: $color-red;
      transition: all 0.3s ease-in-out 0s;
    }
  }
}

.button-group-wrapper {
  margin-top: 30px;
}
.button-group {
  display: flex;
  gap: 20px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  margin-bottom: 10px;
  .btn-tabs-item {
    color: $color-red;
    border: 2px solid $color-red;
    width: 195px;
    &:hover {
      border: 2px solid $color-red;
      background-color: $color-red !important;
      transition: all 0.3s ease-in-out 0s;
      span {
        color: white;
        transition: all 0.3s ease-in-out 0s;
      }
    }
  }

  .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: $color-red;
    border-color: $color-red;
  }
}
.button-group1 {
  display: flex;
  gap: 20px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  margin-bottom: 10px;
  .btn-tabs-item {
    color: $color-red;
    border: 2px solid $color-red;
    width: 250px !important;
    &:hover {
      border: 2px solid $color-red;
      background-color: $color-red !important;
      transition: all 0.3s ease-in-out 0s;
      span {
        color: white;
        transition: all 0.3s ease-in-out 0s;
      }
    }
  }

  .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: $color-red;
    border-color: $color-red;
  }
}
.btn-active {
  border: 2px solid $color-red;
  background-color: $color-red;
  span {
    color: white;
  }
}

// news release
.view-more {
  cursor: pointer;
}
.view-more:hover {
  color: #941414;
  transition: all 0.3s ease-in-out 0s;
}

.h-480 {
  height: 540px;
}
.h-500 {
  height: 420px;
}
.pd-20 {
  padding: 20px;
}

.h-600 {
  height: 560px;
}

.mt-50-twell {
  margin-top: 50px;
}
.slider-mobile {
  display: none;
}
.slider-mobile-wrapper {
  width: 100%;
  height: 100%;
  padding: 0px 10px;
}
.slider-info-mobile {
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.7);
  padding: 20px 10px;
}

.h-50 {
  height: 550px;
  padding-bottom: 50px;
}

@media only screen and (max-width: 600px) {
  .slider-mobile {
    display: block;
  }
  .banner-pc {
    display: none;
  }
  h1 {
    font-size: 26px !important;
  }
  .card-img {
    height: 70%;
  }
  .content-news {
    height: 150px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .pd-20 {
    padding: 10px;
  }
  .swiper-button-prev,
  .swiper-button-next {
    display: none !important;
  }

  .baner-content {
    width: 100%;
    padding: 16px 6px;
  }

  .info-solution {
    width: 100%;
  }
  .h-480 {
    height: 360px;
  }
  .mt-50-twell {
    margin-top: 0px;
  }
}

.empty-wrapper {
  height: 200px;
  width: 100%;
  @include flex-center();
}
