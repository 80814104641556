.news-wrapper {
  border-radius: 8px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  .news-header {
    background-color: $color-red;
    padding: 20px;
    border-radius: 8px 8px 0px 0px;
    color: white;
    font-size: 24px;
    p {
      font-weight: 400;
    }
  }
  .news-item {
    padding: 16px 10px;
    border-bottom: 1px solid #dadada;
  }
  .news-item:last-child {
    border-bottom: unset;
  }
}

.card-news-info {
  border-radius: 8px;
  box-shadow: 0px 0px 6px #dadada;
  width: 100%;
  overflow: hidden;
  height: 430px;
  .card-img {
    height: 250px;
    .ant-image {
      height: 100% !important;
      width: 100%;
      display: flex;
      align-items: center;
      overflow: hidden;
      img {
        width: 100%;
        height: auto !important;
        // height: 100% !important;
      }
    }
  }

  .card-content-info {
    padding: 10px;
    @include flex-column-between();
    height: 165px;
    overflow: hidden;
  }
  &:hover {
    p {
      color: black;
    }
    img {
      transform: scale(1.03, 1.03);
      transition: all 0.3s ease-in-out 0s;
    }
  }
  .news-desc-info {
    width: 100%;
    font-size: 12px;
    font-weight: 400;
  }
}

.news-desc {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.news-desc2 {
  width: 100%;
  height: 28px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 15px;
}
.news-desc1 {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: 400;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  // -webkit-line-clamp: 6;
}

.news-title {
  width: 100%;
  // height: 20px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  // -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}
.news-title1 {
  width: 100%;
  height: 30px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 15px;
}

.card-content {
  padding: 16px 10px 10px 10px;
  @include flex-column-between();
}
.text-indent {
  text-indent: 10px;
}

.h-750 {
  height: 750px;
}

.new-detail-title {
  // text-transform: uppercase;
  margin-bottom: 16px;
  line-height: 36px;
}
.news-detail-info {
  font-size: 12px;
  color: gray;
  .detail-date {
    border-left: 1px solid gray;
    padding-left: 10px;
    margin-left: 10px;
  }
}
.news-detail-content {
  margin-top: 30px;
  p {
    margin-bottom: 16px;
    // text-indent: 12px;
    line-height: 20px;
  }
  ul {
    margin-bottom: 20px;
    li {
      margin-bottom: 6px;
    }
  }
  .news-info-editor {
    img {
      width: 100% !important;
      height: auto;
    }
  }
}

.product-blockchain {
  // height: 300px;
  // border-radius: 8px;
  // box-shadow: 0px 2px 12px 2px #dadada;
  .blockchain-img {
    .ant-image {
      width: 100%;
      img {
        border-radius: 8px;
      }
    }
  }
}

.twell-image {
  width: 100%;
  height: 100%;
  .ant-image {
    width: 100%;
    img {
      border-radius: 8px;
      object-fit: cover;
    }
  }
}

.card-news {
  border-radius: 8px;
  box-shadow: 0px 0px 6px #dadada;
  width: 100%;
  overflow: hidden;
  .ant-row {
    height: 100%;
  }
  .ant-col {
    height: 100%;
  }
}
.card-newshome {
  border-radius: 8px;
  box-shadow: 0px 0px 6px #dadada;
  width: 100%;
  overflow: hidden;
  .card-img {
    .ant-image {
      display: flex;
      align-items: center;
      img.ant-image-img {
        width: 100% !important;
        height: auto !important;
      }
    }
  }
  .card-content {
    border-top: 1px solid #f5f2f2;
    .news-desc {
      -webkit-line-clamp: 2;
    }
  }
  &:hover {
    p {
      color: black;
    }
    img {
      transform: scale(1.03, 1.03);
      transition: all 0.3s ease-in-out 0s;
    }
  }
}

.card-img {
  height: 75%;
  .ant-image {
    height: 100% !important;
    width: 100%;
    img {
      height: 100% !important;
    }
  }
}
.card-img1 {
  height: 100%;
  .ant-image {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    img {
      width: 100%;
      height: auto;
      // height: 100%;
    }
  }
}

.card-news:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  transition: all 0.3s ease-in-out 0s;
  span {
    color: $color-red;
    transition: all 0.3s ease-in-out 0s;
  }
  p {
    color: black;
  }
  img {
    transform: scale(1.03, 1.03);
    transition: all 0.3s ease-in-out 0s;
  }
}
