.header {
  box-shadow: 0px 1px 2px 0px #00000040;
  background-color: #fff;
  position: fixed;
  top: 0;
  z-index: 10;
  height: 70px;
  .header__container {
    height: 100%;
    @include flex-between();
    .ant-row {
      width: 100%;
      align-items: center;
    }
    .ant-image-img {
      width: 114px;
      height: 61px;
      object-fit: contain;
    }
    .header__nav__container {
      @include flex-between();
      .nav__item {
        .nav__link {
          @include font-style(16px, 500, $color-text);
          text-decoration: none;
          position: relative;
          &:hover:after {
            width: 100%;
            transform: scaleX(1);
          }
          &:after {
            content: "";
            transform: scaleX(0);
            height: 2px;
            background-color: #e3282c;
            position: absolute;
            top: 28px;
            display: block;
            transition: all 0.3s ease-in-out 0s;
          }
          span {
            font-weight: 600;
          }
        }
        .nav__link.active {
          &:after {
            width: 100%;
            transform: scaleX(1);
          }
        }
      }
    }
  }
  .change_language_container {
    display: flex;
    align-items: center;
    border-radius: 8px;
    padding: 8px;
    border: 1px solid $color-border;
    span {
      margin: 0px 5px;
      @include font-style(16px, 400, $color-text);
    }
  }
}

.icon-menu {
  border: 1px solid #838181;
  padding: 12px 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  color: #838181;
  margin-left: 10px;
}

/* drawer */
.ant-drawer {
  z-index: 9 !important;
  top: 70px;
  .ant-drawer-mask {
    max-width: 100%;
    background-color: unset;
  }
  .ant-drawer-content-wrapper {
    max-width: 100%;
    height: 100vh !important;
  }
  .ant-drawer-content {
    background: unset !important;
    max-width: 100%;
    .ant-drawer-body {
      width: 100%;
    }
  }
}

.ant-drawer-top {
  top: 60px !important;
}

.ant-drawer .ant-drawer-mask {
  background: rgba(0, 0, 0, 0.9);
}

.item-drawer {
  color: white;
  margin-bottom: 34px;
  font-size: 20px;
  transition: all linear 0.3s;
  .nav-link {
    color: white;
    span {
      font-weight: 500;
    }
  }
  .active {
    color: $color-red;
  }
  &:hover {
    color: $color-red;
  }
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
  padding: 5px;
}
