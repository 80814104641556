.about_container {
  margin-bottom: 80px;
  p {
    @include font-style(16px, 400, $color-text);
    line-height: 24px;
  }
  h1 {
    text-align: center;
  }
  .content_about {
    #ceo_message {
      margin-bottom: 24px;
      .content {
        p {
          margin-bottom: 6px;
          // text-align: justify;
        }
        h3 {
          @include font-style(32px, 600, #333);
          margin-bottom: 24px;
        }
        img {
          margin-top: 14px;
          width: 225px;
          height: 88px;
        }
      }
    }
    #mission_and_vision {
      margin-bottom: 40px;
      #img_mission {
        width: 100%;
      }
      .content {
        margin-left: 80px;
        h3 {
          @include font-style(40px, 600, $color-title);
          margin-bottom: 20px;
        }
        .miss_vision_item {
          margin-bottom: 30px;
          section {
            display: flex;
            align-items: center;
            margin-bottom: 16px;
            img {
              width: 35px;
              height: 35px;
              margin-right: 16px;
            }
            span {
              @include font-style(20px, 500, $color-text);
            }
          }
        }
      }
    }
  }
  .facts {
    .bg_map {
      margin-top: 18px;
      background: url(../../img/map.png);
      padding: 60px 0px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      .content_text {
        margin-right: 136px;
        h3 {
          @include font-style(32px, 600, #333);
          margin-bottom: 24px;
        }
      }
      .box_circle_container {
        display: flex;
        justify-content: space-between;
        .box_circle {
          text-align: center;
          .item {
            @include font-style(36px, 600, #ffffff);
            width: 150px;
            height: 150px;
            background: url(../../img/circle_red.webp);
            display: flex;
            align-items: center;
            justify-content: center;
            background-position: center;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
  .key_values {
    margin-top: 40px;
    p {
      text-align: center;
      margin-top: 10px;
      margin-bottom: 38px;
    }
    .img_values {
      text-align: center;
    }
  }
  .management_team {
    margin-top: 40px;
    .ant-row {
      .ant-col {
        height: 360px;
      }
    }
    .member_container {
      position: relative;
      height: 100%;
      background-color: #f6f6f6;
      border-radius: 8px;
      padding: 32px 28px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      .border_member {
        background: url(../../img/border_member_team.png);
        background-repeat: no-repeat;
        width: 210px;
        height: 210px;
        @include flex-center();
        margin-bottom: 10px;
        img {
          width: 100%;
        }
      }
      span {
        margin-top: 12px;
        @include font-style(18px, 500, $color-text);
        margin-bottom: 4px;
      }
      .member_name:hover {
        color: $color-red;
        cursor: pointer;
      }
      .ant-drawer {
        top: 0px;
        outline: none !important;
        .ant-drawer-mask {
          background-color: rgba(255, 255, 255, 0.88);
        }
        .ant-drawer-content-wrapper {
          height: 100% !important;
        }
        .ant-drawer-header-title {
          .ant-drawer-close {
            position: absolute;
            top: 10px;
            right: 0;
            &:hover .anticon-close {
              color: $color-red;
              cursor: pointer;
            }
          }
          .ant-drawer-title {
            @include font-style(20px, 500, $color-red);
          }
        }
        .ant-drawer-body {
          p {
            @include font-style(14px, 500, $color-text);
            text-align: left;
            margin-bottom: 4px;
          }
        }
      }
      // .information_detail_member {
      //   position: absolute;
      //   width: 100%;
      //   border-radius: 8px;
      //   background-color: rgba(255, 255, 255, 0.88);
      //   transition: all 0.8s ease-in-out;
      //   padding: 20px;
      //   box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      //   // top: 100%;
      //   // opacity: 0;
      //   // height: 0px;
      //   opacity: 1;
      //   top: 0px;
      //   height: 100%;
      //   h1 {
      //     margin-bottom: 8px;
      //   }
      //   p {
      //     @include font-style(14px, 500, $color-text);
      //     text-align: left;
      //     margin-bottom: 4px;
      //   }
      //   .icon_close {
      //     position: absolute;
      //     top: 0;
      //     right: 16px;
      //     margin-top: 0px;
      //   }
      //   .icon_close:hover {
      //     .anticon-close {
      //       color: $color-red;
      //       cursor: pointer;
      //     }
      //   }
      // }
    }

    .information_detail_member {
      // opacity: 1;
      // top: 0px;
      // height: 100%;
    }
  }
  .why_us {
    margin-top: 40px;
    a {
      text-decoration: none;
    }
    .info_us_container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 250px;
      .info_title {
        @include font-style(20px, 500, $color-text);
        margin-bottom: 18px;
        min-height: 50px;
      }

      .info_title.active {
        color: $color-title;
      }

      .icon_arrow {
        margin-top: 20px;
        display: inline-block;
        width: 35px;
        height: 35px;
        background-color: black;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:hover {
          background-color: $color-red;
        }
      }
      .icon_arrow.active {
        background-color: $color-red;
      }
    }
    .info_reference {
      margin-top: 50px;
      display: flex;
      align-items: center;
      section {
        margin-left: 30px;
        p:first-child {
          @include font-style(24px, 500, $color-text);
          margin-bottom: 16px;
        }
        span {
          @include font-style(14px, 500, $color-red);
          margin-top: 16px;
          display: inline-block;
        }
      }
    }
  }
  .award_container {
    margin-top: 40px;
    .swiper {
      // max-width: 800px;
      height: 700px;
      padding: 10px 40px;
    }

    .swiper-slide {
      // background-position: center;
      // background-size: cover;
      // width: 600px;
      // height: 600px;
    }

    .swiper-slide .ant-image {
      height: 100%;
      width: 100%;
      img {
        height: 100%;
        object-fit: scale-down;
      }
    }
  }

  .presentation_container {
    margin-top: 40px;
    .action_more {
      margin-top: 30px;
      text-align: center;
      a {
        text-decoration: none;
      }
      span {
        @include font-style(16px, 500, $color-text);
      }
      .ant-btn {
        padding: 10px !important;
        margin-left: 10px;
        .anticon-download {
          margin-left: 0px !important;
          color: #ffffff;
          font-size: 18px;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .content_about {
    #ceo_message {
      #img_ceo_mess {
        margin-top: 10px;
        object-fit: contain;
      }
    }
    #mission_and_vision {
      .ant-row {
        flex-direction: column-reverse;
      }
      .content {
        margin-left: 0px !important;
      }
    }
  }

  .facts {
    margin-top: 80px;
    .bg_map {
      background: none !important;
      padding: 0px !important;
      .content_text {
        margin-right: 0px !important;
        h3 {
          text-align: center;
        }
      }
      .box_circle_container {
        flex-wrap: wrap;
        margin-top: 20px;
        .box_circle {
          margin-bottom: 24px;
        }
      }
    }
  }

  .key_values {
    .img_values {
      img {
        width: 100% !important;
        object-fit: contain;
        height: auto !important;
      }
    }
  }

  .why_us {
    .info_us_container {
      align-items: center;
      flex-direction: row !important;
      min-height: unset !important;
      justify-content: flex-start !important;
      margin: 10px 0px;
      div {
        max-width: 90%;
      }
      .info_title {
        min-height: unset !important;
        margin-bottom: 0px !important;
        margin-right: 10px;
      }
      .info_content {
        display: none;
      }
      .icon_arrow {
        margin-top: 0px !important;
        width: 24px !important;
        height: 24px !important;
        img {
          width: 60%;
        }
      }
    }
    .info_reference {
      flex-wrap: wrap;
      img {
        width: 100%;
      }
      section {
        margin-top: 20px;
        margin-left: 0px !important;
      }
    }
  }

  .award_container {
    .swiper {
      width: auto !important;
      height: 400px !important;
      padding: 0px !important;
      margin-top: 30px;
      .swiper-pagination {
        display: none !important;
      }
    }
  }
}
