@mixin font-family-workSans {
  font-family: "Work Sans";
  font-style: normal;
}
@mixin font-style($size, $weight, $color) {
  font-size: $size;
  font-weight: $weight;
  color: $color;
}
@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
@mixin flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@mixin flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@mixin flex-between-start {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
@mixin flex-column-item-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@mixin flex-column-start {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@mixin flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
@mixin flex-item-end {
  display: flex;
  justify-content: flex-start;
  align-items: end;
}
@mixin flex-item-center {
  display: flex;
  align-items: center;
}
@mixin flex-jus-center {
  display: flex;
  justify-content: center;
}

@mixin flex-column-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@mixin flex-jus-end {
  display: flex;
  justify-content: end;
}
@mixin flex-column-item-start {
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: flex-start;
}
