.career_container {
  margin-bottom: 80px;
  h1 {
    text-align: center;
    margin-bottom: 50px;
  }
  .search_career {
    margin-bottom: 80px;
    height: 84px;
    background-color: #f6f6f6;
    ::placeholder {
      color: $color-placeholder;
      opacity: 1;
    }
    .input_search {
      height: 44px;
      margin-right: 30px;
      padding: 0px 10px;
    }
  }
  .career_item {
    .ant-card {
      border: none;
      box-shadow: 0px 0px 6px 0px #00000040;
      height: 458px;
      .ant-card-cover {
        margin: 0px;
        width: 100%;
        height: 54%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .ant-card-body {
        padding: 20px 10px;
        .name_career {
          @include font-style(20px, 500, $color-text);
          // width: 66%;
          // display: -webkit-box;
          // -webkit-line-clamp: 1;
          // -webkit-box-orient: vertical;
          // overflow: hidden;
          // text-overflow: ellipsis;
        }
        .address_career {
          @include font-style(16px, 400, #727272);
        }
        .desc_career {
          @include font-style(16px, 400, $color-text);
          margin-top: 20px;
          margin-bottom: 10px;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        a {
          position: absolute;
          bottom: 20px;
        }
      }
    }
    .ant-card:hover {
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      .ant-card-meta-description {
        color: $color-red;
      }
    }
  }
}
.careers_recent_container {
  border-radius: 8px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  &_header {
    background-color: $color-red;
    padding: 20px;
    border-radius: 8px 8px 0px 0px;
    color: white;
    font-size: 24px;
    p {
      font-weight: 300;
    }
  }
  .careers_recent_item {
    padding: 16px 10px;
    border-bottom: 1px solid #dadada;
  }
  .careers_recent_item:last-child {
    border-bottom: unset;
  }
  .careers_recent_item_title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 15px;
  }
  .careers_recent_item_desc {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 15px;
  }
}

.career_detail_container {
  margin-bottom: 80px;
  h1 {
    text-align: center;
    margin-bottom: 90px;
    text-transform: uppercase;
  }
  .career_detail_content {
    margin-top: 30px;
    p {
      margin-bottom: 16px;
      // text-indent: 12px;
      line-height: 20px;
    }
    ul {
      margin-bottom: 20px;
      li {
        margin-bottom: 6px;
      }
    }
    .career_editor {
      p {
        img {
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .career_container {
    .search_career {
      .input_search {
        margin-right: 6px;
      }
      .ant-btn {
        padding: 0px 10px;
        span:last-child {
          margin-left: 0px;
        }
      }
    }
  }
}
